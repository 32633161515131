<template>
  <div>
    <Rates
      :rates="rates"
      @saveRate="saveRate"
      :delete="false"
      :create="false"
      title="Management type"
      :fee="false"
      rate="Margin"></Rates>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import Rates from '@/components/App/Plans/Rates'
import gql from 'graphql-tag'
import VSnackbars from 'v-snackbars'

export default {
  components: { Rates, 'v-snackbars': VSnackbars },
  data () {
    return {
      rates: [],
      snackbar: false,
      loading: false,
      messages: []
    }
  },
  methods: {
    async allManagementRates () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query PlansManagementRate {
          pricing {
            allManagementRates {
              name
              margin
              id
            }
          }
        }`
      }).then((data) => {
        this.rates = data.data.pricing.allManagementRates
        this.rates.map(el => {
          el.value = el.margin
          el.readOnly = true
        })
        this.loading = false
      }).catch((err) => {
        this.addSnack('item.name', 'Error', err)
        this.loading = false
        console.log(err)
      })
    },
    async saveRate (item) {
      (await this.$apollo.mutate({
        mutation: gql`mutation PlansManagementRateUpdate($input: ManagementRateUpdateInput! ) {
          pricing {
            managementRate {
              update(input: $input) {
                id
                name
                margin
              }
            }
          }
        }`,
        // Parameters
        variables: {
          input: {
            id: item.id,
            margin: item.value
          }
        }
      })
        .then((data) => {
          console.log(item)
          this.addSnack(item.name, 'Success', item.name + ' was updated with success')
        })
        .catch((err) => {
          console.log(err)
          this.addSnack('item.name', 'Error', err)
        }))
    },
    // async deleteRate (item) {
    //   (await this.$apollo.mutate({
    //     mutation: gql`mutation PlansManagementRateDelete($id: [ID!]!) {
    //       pricing {
    //         managementRate {
    //           delete(id: $id)
    //         }
    //       }
    //     }`,
    //     // Parameters
    //     variables: {
    //       id: item.id
    //     }
    //   })
    //     .then(() => {
    //       this.addSnack(item.name, 'Success', item.name + ` was deleted with success`)
    //       let index = this.rates.indexOf(this.rates.find(el => el.id === item.id))
    //       this.rates.splice(index, 1)
    //     })
    //     .catch(() => {
    //       console.log(item)
    //       this.addSnack(item.name, 'Error', item.name + ` was not deleted`)
    //     }))
    // },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    },
    doCopy (action, value) {
      const vm = this
      this.$copyText(value).then(function () {
        vm.addSnack(value, 'Success', action + ' text copied')
      }, function (e) {
        console.log(e)
      })
    }
  },
  created () {
    this.allManagementRates()
  }
}
</script>

<style scoped>

</style>
